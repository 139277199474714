<template>
  <div id="GetMsgDetail">
    <div class="message">
      <el-form label-position="right" label-width="80px" :model="msgData">
        <el-form-item label="用户姓名:">
          <p>{{ msgData.touristName }}</p>
        </el-form-item>
        <el-form-item label="发布时间:">
          <p>{{ msgData.creatTime }}</p>
        </el-form-item>
        <el-form-item label="信件标题:">
          <p>{{ msgData.messageTitle }}</p>
        </el-form-item>
        <el-form-item label="信件分类:">
          <p>{{ msgData.messageType }}</p>
        </el-form-item>
        <el-form-item label="信件内容:">
          <p>{{ msgData.messageContent }}</p>
        </el-form-item>
      </el-form>
    </div>
    <div class="reply">
      <el-form label-position="right" label-width="80px" :model="msgData">
        <el-form-item label="回复人:">
          <p>{{ msgData.replyName }}</p>
        </el-form-item>
        <el-form-item label="回复时间:">
          <p>{{ msgData.replyTime }}</p>
        </el-form-item>
        <el-form-item label="回复内容:">
          <p>{{ msgData.replyContent }}</p>
        </el-form-item>
      </el-form>
    </div>
    <div class="close"><a >
      <el-button type="success" @click="closeWin" plain>关闭</el-button>
      </a></div>
  </div>
</template>

<script>
// import {findOpenMessages} from '../../service/getData';
export default {
  name: 'GetMsgDetail',
  data() {
    return {
      msgData: {}
    };
  },
  mounted() {
    this.msgData = JSON.parse(this.$route.query.row);
    console.log(this.msgData);
  },
  methods: {
    closeWin() {
      window.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  border: solid 1px rgb(193 224 255);
  background-color: rgb(226 242 250);
}
.reply {
  border: solid 1px rgb(193 224 255);
  background-color: rgb(242 253 255);
}
.close {
  padding: 20px;
  background-color: rgb(193 224 255);
  a {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
}
</style>
